import React from 'react';
import styled from 'styled-components';
import Banner from '../components/Banner';
import Footer from '../components/Footer';

const NewsStyled = styled.div`
>.container{
    padding: 70px 0;
    
        >.news{
            margin-top: 50px;
            >h3{
                
            }

            >.date{
                color: #999;
                font-size: 12px;
                margin: 10px 0 !important;
            }

            >p{
                font-size: 14px;
            }

            >div{
                text-align: right;
                margin-top: 20px;
                >a{
                    text-decoration: none;
                }
            }
        }

}
`

const News = () => {
    return (
        <NewsStyled>
            <Banner mainLabel="About Organization" />
            <div className='container'>
                <h3>About PRIZMA</h3>
                <div className='line'></div>

                <p>
                PRIZMA is a research and training Center committed to advancing solutions to global health
issues in Tajikistan. It was established in 2008 as a collaborative effort between the
International Center on Responses to Catastrophes at the University of Illinois at Chicago and
the Ministry of Health in Tajikistan. The Center also conducts research with Tajik migrants in
Russia and provides training for researchers through a satellite office in Moscow. Through its
research and training activities PRIZMA aims to build capacity of local scientists and
researchers in order to develop a sustainable research infrastructure in Tajikistan.
                    </p>


                <br />
                <br />

                <h3>Mission</h3>
                <div className='line'></div>

                <p>
                PRIZMA develops evidence-based solutions to migration and HIV/AIDS issues in Tajikistan
                through rigorous research and training for research.
                </p>

                <br />
                <br />

                <h3>Vision</h3>
                <div className='line'></div>
                <p>
                PRIZMA is one of the leading research and training Centers in Tajikistan which envisions:
• Providing migrants with access to adequate HIV prevention programs
• Building capacity of the next generation of research scientists capable to conduct independent
research in different global health issues
                    </p>

                <br />
                <br />

                <h3>Objectives</h3>
                <div className='line'></div>

                <p>PRIZMA brings leading multidisciplinary global health experts together and creates crosscutting
international partnerships among government, NGOs and academic institutions to achieve the
following objectives:
                    <br />
                    <br />
                    • To build a sustainable infrastructure and scientific capacity in Tajikistan in order to conduct
research that will inform policies and programs on migration and HIV/AIDS and other global
health issues
                    <br />
                    <br />
                    • To conduct multidisciplinary research studies focused on migration and HIV/AIDS that includes
                    scientifically-based prevention interventions deliverable in low resource settings
                    <br />
                    <br />
                    • To build capacity of junior Tajik researchers in order for them to become independent
researcher s in future
                    <br />
                    <br />
                    • To provide educational and training opportunities for US and Tajik Medical students through
an exchange program</p>

                <br />
                <br />

                <h3>Core activities:</h3>
                <div className='line'></div>

                <p>
                • Train research scientists in Tajikistan to conduct epidemiological, behavioral and social
                science research in global health field
                    <br />
                    <br />
                    • Train research scientists in Tajikistan to conduct epidemiological, behavioral and social
science research in global health field
                    <br />
                    <br />
                    • Promote short and long -term exchange of medical students and junior researchers in order to
build academic partnership between US universities and universities in Tajikistan
                </p>


            </div>
            <Footer />
        </NewsStyled>
    );
}

export default News;
