import styled from 'styled-components';
import { IoDocumentTextOutline } from "react-icons/io5";



const FileStyled = styled.div`
background-color: aliceblue;
align-items: center;
text-align: center;
padding: 20px;
border-radius: 10px;
width: 200px;
>svg{
    font-size: 50px;
    color: darkblue;
}

`

const File = ({ link, name, id }) => {
    return (
        <FileStyled key={id}>
            <IoDocumentTextOutline />
            <br />

            <a target="_blank" href={link}>{name}</a>
        </FileStyled>
    )
}

export default File;