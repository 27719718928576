import React from 'react';
import styled from 'styled-components';
import Banner from '../components/Banner';
import Law from "../images/law.png"
import Market from "../images/market-veiw.png"
import TajLising from "../images/tajLising.png"
import Operations from "../images/operations.png"
import Footer from '../components/Footer';

import { Collapse } from "antd";

const items = [
    {
      key: '1',
      label: '2008-2013 Migrancy, Masculinity, HIV Risk and Prevention among Tajik male migrants in Moscow, Russia.',
      children: <p>This application addresses the major global health problem of HIV prevention amongst male labor
      migrants in Central Asia. The study focuses on married men from Tajikistan working in Moscow
      and their risks for acquiring HIV through having sex with female sex workers and then transmitting
      the infection to their wives or female sexual partners. This five-year quantitative and qualitative
      study gathers data from migrants in Moscow, sending families in Tajikistan, female partners and sex
      workers in Moscow, and organizations involved with migrants in both locations. It develops
      contextual knowledge on: 1) the processes through which social, cultural, and psychological factors
      shape masculinity; 2) how masculine norms impact male migrants&#39; HIV risk and preventive
      behaviors; and 3) how to enhance HIV prevention skills through addressing masculine norms in the
      real world contexts of migrants&#39; lives and the organizations working with them. The goals are
      focused both on building formative knowledge and on developing innovative intervention strategies
      to inform HIV prevention.</p>,
    },
    {
      key: '2',
      label: '2014-2018. Addressing Mental Illness and Physical Comorbidities in Migrants and Their Families.',
      children: <p>This project addresses the adverse impact of migration on chronic non-communicable diseases
      (NCD), specifically in the area of mental illness and physical comorbidities. The overall aim of is to
      build research capacity in low- and middle-income countries with a focus on migration in Eastern
      Europe and Central Asia from two of the world’s highest migrant sending countries, Kosovo and
      Tajikistan. This research training program trains early- to mid-career postdoctoral researchers from
      Kosovo and Tajikistan in multidisciplinary approaches to NCD research through training at
      University of Illinois at Chicago (UIC).  Additionally, this project builds research capacity at partner
      institutions by supporting the UIC-mentored research projects and by training an estimated 250
      participants per year (both live and on-line) who are either academics, educators, practitioners,
      policymakers, or stakeholders on pertinent research methods, issues, and infrastructure.  The project
      also develops a diverse network of researchers from the U.S., Kosovo, and Tajikistan dedicated to
      sharing knowledge and skills through convening annual meetings, trainings, and dissemination. The
      research training program focuses on the impact of migration upon chronic non-communicable
      diseases in the areas of mental illness (e.g. depression, anxiety, PTSD, and substance abuse) and
      risks for physical comorbidities (e.g. cardiovascular, cerebrovascular, and occupational diseases).</p>,
    },
    {
      key: '3',
      label: '2015-2017. MASLIHAT Intervention for Tajik Male Migrants IDUs',
      children: <p>Labor migration is a major contributor to fueling the global AIDS epidemic and also the movement
      of HIV across country borders and populations. Migrants who inject drugs while in a host country
      are at especially high risk. Tajikistan, a small country in Central Asia, exports more than a million
      Tajiks annually, many of whom inject drugs, to work outside of their own country. Our earlier
      research on Tajik labor migrants in Moscow showed them to be at alarmingly high normative and
      behavioral risk for HIV due to risky drug use, needle sharing, alcohol consumption, and unsafe sex
      with casual and paid sex partners. To help curb transmission in this vulnerable population, the
      proposed study will develop the Migrants&#39; Approached Self-Learning Intervention in HIV/AIDS for
      Tajiks (MASLIHAT) prevention model. The model recruits and trains current and former Tajik
      migrant IDUs as peer educators in delivering the intervention to IDUs and others in their social
      networks while simultaneously reducing their own risk. The MASLIHAT Intervention and our
      research efforts build synergistically on 3 theoretical models: social-cognitive-behavioral theory,
      Social network theory and Yang&#39;s Theory of Migration. The model itself culturally adapts the
      successful SHIELD model, which is a CDC- designated evidence-based best practice intervention in
      the U.S., for use with migrant IDUs.</p>,
    },
    {
        key: '4',
        label: '2020 – 2024. MASLIHAT Intervention for Tajik Male Migrants Who Inject Drugs',
        children: <p>Labor migration is a major contributor to fueling the global AIDS epidemic and also the movement
        of HIV across country borders and populations. Migrants who inject drugs while in a host country
        are at especially high risk. Tajikistan, a small country in Central Asia, exports more than a million
        Tajiks annually, many of whom inject drugs, to work temporarily outside of their own country. Our
        earlier research on Tajik labor migrants in Moscow showed them to be at alarmingly high normative
        and behavioral risk for HIV due to risky drug use, needle sharing, alcohol consumption, and unsafe
        sex with casual and paid sex partners. To help curb transmission in this vulnerable population, we
        developed and pilot tested the &quot;Migrants&#39; Approached Self- Learning Intervention in HIV/AIDS for
        Tajiks&quot; (MASLIHAT) prevention model. The model recruits and trains current and former Tajik
        migrants who inject drugs as &quot;peer educators&quot; in delivering the intervention to others in their social
        networks while simultaneously reducing their own risk. The MASLIHAT Intervention and our
        research efforts build synergistically on 3 theoretical models: social-cognitive-behavioral theory,
        social network theory and Yang&#39;s Theory of Migration. The model itself culturally adapts the
        successful SHIELD model, which is a CDC- designated evidence-based best practice intervention in
        the U.S., for use with Tajik labor migrants in Russia who inject drugs. This study will test the
        efficacy of the MASLIHAT intervention against a control condition designed to be equal to the
        intervention condition in the number of sessions, duration, and interest level.</p>,
      },

      {
        key: '5',
        label: '2013 – 2015. The CARAVAN study on tuberculosis among migrants from Central Asia.',
        children: <p>The survey was conducted among migrants who returned to Kyrgyzstan, Tajikistan, and Uzbekistan,
        as well as among migrants from the same countries staying in Kazakhstan at the time of the survey.
        The project involved the most hidden and potentially more vulnerable migrants from different work
        areas like agriculture, construction, and trade. Thus, this study acquired knowledge about this
        vulnerable group, which will be used in policy planning, creating measures to combat tuberculosis,
        including tuberculosis with multidrug-resistant tuberculosis among migrant workers from Central
        Asia. The study aimed to assess the prevalence of tuberculosis, the level of knowledge about
        tuberculosis, access to diagnosis and treatment services and to identify multi-level risk factors
        related to tuberculosis among migrant workers from Kyrgyzstan, Tajikistan, and Uzbekistan.</p>,
      },

      {
        key: '6',
        label: '2016-2019 Advancing Stepped Care for Women&#39;s Common Mental Disorders in an LMIC',
        children: <p>Globally, young women bear a disproportionate burden of common mental disorders especially
        including depression and anxiety. Lifting this burden in low- and middle-income countries (LMICs)
        requires task sharing, which entails using non-specialists and laypersons to deliver mental health
        services. Several trials of task sharing interventions in LMICs have shown modest improvement.
        Obstacles to further progress include lack of evidence regarding what conditions should be treated,
        with what interventions, by whom, and how to overcome access obstacles. This R21 application
        advances knowledge about task sharing by developing and testing for feasibility a stepped care
        model for LMICs using primary care (PC) nurses and mental health peers to treat depression and
        anxiety. It also integrates neuroscience-based prediction models by investigating how low-cost, brief
        Research Domain Criteria (RDoC) tools can assist in evaluating and targeting stepped care. RDoC is
        an NIMH initiative to use dimensional, disease agnostic, brain based tools to better understand the
        key features of mental illness.</p>,
      },

      {
        key: '7',
        label: '2019-2024 Scaling-Up Stepped Care for Women&#39;s Mental Health in Primary Care in an LMIC',
        children: <p>This new R01 application responds to PAR-18-835 by advancing task sharing in testing the
        effectiveness and implementation of a stepped care model for LMICs. It uses nurses and mental
        health peers to treat depression and potential co-occurring anxiety among women in primary care.
        To improve implementation, this study examines the impact of clinic implementation teams in
        readying the clinics and service providers.
        Aim 1 assesses the effectiveness of the stepped care model with 420 women who have depression
        and potential co-occurring anxiety, recruited from 12 primary care clinics in Tajikistan, compared
        with standard of care plus provision of healthy lifestyle materials, with another 210 women recruited
        from 6 primary care clinics, including assessing mediators and moderators (e.g. executive control
        efficiency, trauma exposure).
        Aim 2 assesses whether a clinic implementation team moderates women’s reduction in depression
        
        post-intervention, as well as clinic-level (leadership support and degree of implementation) and
        provider-level (motivation and fidelity) moderators.
        Aim 3 establishes a national mental health research network that focuses on improving the standard
        of mental health care and access to services by building mental health implementation research
        capacity. The knowledge produced through this study will inform stepped care models for mental
        health in LMICs such as Tajikistan and in low-resource settings in the United States.</p>,
      },
  ];

const LisingStyled = styled.div`
>.container{
    padding: 70px 0;

    >.blocks{
        >.block{
            margin-top: 20px;
        display: flex;
        justify-content: space-between;
        color: #fff;

        >.law{
            background-image: url(${Law});
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 500px;
            height: 230px;
            padding: 180px 0 0 20px; 
        }

        >.market{
            background-image: url(${Market});
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 500px;
            height: 230px;
            padding: 180px 0 0 20px; 
        }

        >.tajlising{
            background-image: url(${TajLising});
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 500px;
            height: 230px;
            padding: 180px 0 0 20px; 
        }

        >.operations{
            background-image: url(${Operations});
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 500px;
            height: 230px;
            padding: 180px 0 0 20px; 
        }
        }
        
    }
}

`

const Lising = () => {
    return (
        <LisingStyled>
            <Banner mainLabel="Projects of Organization" />
            <div className='container'>
                <h3>Our Projects</h3>
                <div className='line' />

                <Collapse items={items} defaultActiveKey={['1']} />

            </div>

            <Footer />
        </LisingStyled>
    );
}

export default Lising;
