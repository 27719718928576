import React from 'react';
import styled from 'styled-components';
import Banner from '../components/Banner';
import AboutCredits from '../components/AboutCredits';
import CreditPoints from '../components/CreditPoints';
import CreditFaq from '../components/CreditFaq';
import Footer from '../components/Footer'

const HistoryStyled = styled.div`
`

const History = () => {
    return (
        <HistoryStyled>
            <Banner mainLabel="News"/>
             <AboutCredits/>
             
            {/* <CreditPoints/>
            <CreditFaq/> */}

        
            <Footer/>
        </HistoryStyled>
    );
}

export default History;
