import React from 'react';
import styled from "styled-components";
import Header from '../components/Header';
import AboutAs from '../components/AboutAs';
import News from '../components/News';
import Footer from '../components/Footer';
import Background from "../images/header-background.png"
import Banner from '../components/Banner';

const MainStyled = styled.div `

>.header{
    background-image: url(${Background});
    padding:80px 0 ;
    >div{
        color:#fff
    }
}

`

const Main = () => {
    return (
        <MainStyled>
            <Banner mainLabel="Welcome Prizma TJ !" secondLabel="PRIZMA is a research and training Center committed to advancing solutions to global health
issues in Tajikistan."/>
           <AboutAs/>
           <News/>
           <Footer/>
        </MainStyled>
    );
}

export default Main;
