import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
} from "firebase/firestore";

const Admin = () => {

    const [users, setUsers] = useState([]);
    const [reports, setReports] = useState([]);
  
    useEffect(() => {
        const fetchUsers = async () => {
          const usersCollectionRef = collection(db, "users");
          const data = await getDocs(usersCollectionRef);
          setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        const fetchReports = async () => {
            const usersCollectionRef = collection(db, "reports");
            const data = await getDocs(usersCollectionRef);
            setReports(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          };
    
        fetchUsers();
        fetchReports();
      }, []);


 




    return (
        <div>
            Test
            <ul>
        {users.map((user) => (
          <li key={user.id}>
            {user.name} - {user.age}
          </li>
        ))}
      </ul>

      <ul>
        {reports.map((report) => (
          <li key={report.id}>
            {report.report_name} - <a href={report.report_file}>Report Link</a> 
          </li>
        ))}
      </ul>
        </div>
    );
}

export default Admin;
