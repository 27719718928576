import React from 'react';
import styled from 'styled-components';
import Up from "../images/up.png"
import Down from "../images/down.png"
import Balance from "../images/balance.png"
import Done from "../images/done.png"

const CreditPointsStyled = styled.div `
padding: 50px 0;
text-align: center;
>.container{
    >.points{
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        >.point{
            >img{
                width: 40px;
            }
            >p{
                width: 200px;
                margin-top: 15px;
                font-size: 12px;
            }
        }
    }
}
`

const CreditPoints = () => {
    return (
        <CreditPointsStyled>
            <div className='container'>
                <h3>Цели <br/> Бюро кредитных историй</h3>
                <div className='points'>
                    <div className='point'>
                        <img src={Up}/>
                        <p>Повышение прозрачности кредитного рынка</p>
                    </div>

                    <div className='point'>
                        <img src={Down}/>
                        <p>Снижение кредитных рисков</p>
                    </div>

                    <div className='point'>
                        <img src={Balance}/>
                        <p>Стимулирование ответственного заимствования</p>
                    </div>

                    <div className='point'>
                        <img src={Done}/>
                        <p>Расширение доступа к кредитам</p>
                    </div>
                </div>
            </div>
        </CreditPointsStyled>
    );
}

export default CreditPoints;
