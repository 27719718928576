import React from 'react';
import styled from 'styled-components';
import LogoOrien from "../images/orienlogo.png"
import Eskhata from "../images/eskhata.png"
import Razvitiya from "../images/razvitiya.png"
import Amonat from "../images/amonat.png"
import Miro from "../images/micro.png"
import Tib from "../images/tib.png"

const AboutAsStyled = styled.div`
padding:70px 0;
background-color: #F6F6F6;

>div{
    >.partners{
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        >.partner{
            transition: all 0.5s ease;

            &:hover{
                -webkit-box-shadow: 0px 14px 20px -12px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 14px 20px -12px rgba(0,0,0,0.75);
            box-shadow: 0px 14px 20px -12px rgba(0,0,0,0.75);
            }
            padding: 30px 5px;
            text-align: center;
            width: 200px;
            background-color: #fff;
            >p{
                font-weight: 400;
                font-size: 14px;
                margin: 15px 0 5px 0;
            }
            >a{
                color: #0400B7;
                text-decoration: none;
                font-size: 14px;
                font-weight: 200;
                cursor: pointer;
            }
            >img{
                width: 70px;
            }
        }
    }
}

`

const partners = [
    {
        name: "University of Illinois at Chicago, USA.",
        link: "uic.edu",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/University_of_Illinois_at_Chicago_circle_logo.svg/300px-University_of_Illinois_at_Chicago_circle_logo.svg.png"
    },

    {
        name: "Republican scientific and clinical center of family medicine",
        link: "fammedcenter.tj",
        image: Tib
    },

    {
        name: "Avicenna Tajik State Medical University",
        link: "tajmedun.tj",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbMzHcsnZ1mNE02SfvZd2x64ArV9ST9Fl9c6wAuAYi3byqIkLv1xyI5Yvn-DLWceY4Q6k&usqp=CAU"
    }
]


const AboutAs = () => {
    return (
        <AboutAsStyled>
            <div className='container'>
                <h3>About Organization</h3>
                <div className='line'></div>
                <p>PRIZMA is a research and training Center committed to advancing solutions to global health
issues in Tajikistan. It was established in 2008 as a collaborative effort between the
International Center on Responses to Catastrophes at the University of Illinois at Chicago and
the Ministry of Health in Tajikistan. The Center also conducts research with Tajik migrants in
Russia and provides training for researchers through a satellite office in Moscow. Through its
research and training activities PRIZMA aims to build capacity of local scientists and
researchers in order to develop a sustainable research infrastructure in Tajikistan.
                
       </p>

                <div className="partners">

                    {partners.map((partner) => <div key={partner.id} className="partner">
                    <img style={{borderRadius:"100px", width:"100px"}} src={partner.image} />
                        <p>{partner.name}</p>
                        <a  href={"https://"+partner.link}>{partner.link}</a>
                    </div>)}

                    
                </div>
            </div>
        </AboutAsStyled>
    );
}

export default AboutAs;
