import React from 'react';
import styled from 'styled-components';

const FooterStyled = styled.div`
background-color: #0B2B3F;
color: #fff;
padding: 20px 0;
>div{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 900;
    >div{
        >p{
            font-weight: 200;

            &:first-child{
                margin-bottom: 5px;
            }
        }

        >a{
            color:#fff;
            text-decoration: none;

        }
    }
}
`

const Footer = () => {
    return (
        <FooterStyled>
            <div className='container'>
                <div>
                    <p>Rudaki Avenue 137. Office 509a. Dushanbe, Tajikistan.</p>
                    <p>© PRIZMA</p>
                </div>
                <div>
                    <p>For calls from anywhere in the world</p>
                    <a>+992 93 843 2398</a>
                </div>

                <div>
                    <p>Email: </p>
                    <a>info@prizma.tj</a>
                </div>
               
            </div>
        </FooterStyled>
    );
}

export default Footer;
