import React, { useState, useEffect } from "react";
import styled from 'styled-components';

import News from "./NewsComp";

import { db } from "../firebase";
import {
    collection,
    getDocs,
} from "firebase/firestore";

const AboutCreditsStyled = styled.div `
    background-color: #f6f6f6;
    padding: 70px 0;

    >div{
        >p{
            font-weight: 300;
        }
    }

`





const AboutCredits = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {


        const fetchNews = async () => {
            const usersCollectionRef = collection(db, "news");
            const data = await getDocs(usersCollectionRef);
            setNews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };


        fetchNews();
    }, []);

    return (
        <AboutCreditsStyled>
            <div className='container'>
            <h3>Latest News</h3>
            <div className='line'></div>
                {/* <div className='line'></div>
                <p>Бюро кредитных историй (БКИ) – это независимая организация, которая собирает, хранит и предоставляет информацию о кредитной истории заемщиков. Эта информация помогает банкам и другим кредиторам оценивать кредитоспособность потенциальных заемщиков.</p> */}
                 {news.map((newss) => (<News id={newss.id} title={newss.news_title} data={newss.news_data} text={newss.news_text}/>))}
               

            </div>
        </AboutCreditsStyled>
    );
}

export default AboutCredits;
