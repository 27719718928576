
import Footer from '../components/Footer';
import styled from 'styled-components';
import Banner from '../components/Banner';
import React, { useState, useEffect } from "react";


import News from "../components/NewsComp";

import { db } from "../firebase";
import {
    collection,
    getDocs,
} from "firebase/firestore";

const PublicationsStyled = styled.div `
  background-color: #f6f6f6;
>.container{
    background-color: #f6f6f6;
    padding: 70px 0;
}
`

const Publications = () => {
    const [pubs, setPubs] = useState([]);

    useEffect(() => {


        const fetchPubs = async () => {
            const usersCollectionRef = collection(db, "publications");
            const data = await getDocs(usersCollectionRef);
            setPubs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };


        fetchPubs();
    }, []);

    return (
        <PublicationsStyled>
        <Banner mainLabel="Publications"/>
        <div className='container'>
        <h3>Lastest Publications</h3>
        <div className='line'/>
        {pubs.map((pub) => (<News id={pub.id} title={pub.pub_title} data={pub.pub_data} text={pub.pub_text}/>))}
               
        </div>
        <Footer/>
        </PublicationsStyled>
    );
}

export default Publications;
