import React from 'react';
import styled from 'styled-components';
import { IoIosArrowForward } from "react-icons/io";


const CreditFaqStyled = styled.div `
background-color: #f6f6f6;
padding: 70px 0;

>.container{
    >.faq{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        padding: 10px;
        transition: all 0.5s ease;
        &:hover{
            margin-right: 10px;
                -webkit-box-shadow: 0px 14px 20px -12px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 14px 20px -12px rgba(0,0,0,0.75);
            box-shadow: 0px 14px 20px -12px rgba(0,0,0,0.75);
            }
        >p{
            font-weight: 300;
        }
    }


    >.btn{
        margin-top: 40px;
        text-align: center;
        
        
        >a{
        padding: 10px;
        background-color: #2F4E62;
        color:#fff;
        text-decoration: none;
        margin: 0 auto;
        transition: all 0.5s ease;
        &:hover{
            margin-bottom: 20px;
                -webkit-box-shadow: 0px 14px 20px -12px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 14px 20px -12px rgba(0,0,0,0.75);
            box-shadow: 0px 14px 20px -12px rgba(0,0,0,0.75);
            }
    }
    }
    
}

`

const CreditFaq = () => {
    return (
        <CreditFaqStyled>
            <div className='container'>
                <h3>Чем полезно БКИ заемщикам?</h3>
                <div className='line'></div>
                <div className='faq'>
                    <span>Позволяет узнать свою кредитную историю</span>
                    <IoIosArrowForward />
                </div>

                <div className='faq'>
                    <span>Позволяет узнать свою кредитную историю</span>
                    <IoIosArrowForward />
                </div>

                <div className='faq'>
                    <span>Позволяет узнать свою кредитную историю</span>
                    <IoIosArrowForward />
                </div>

                <div className='faq'>
                    <span>Позволяет узнать свою кредитную историю</span>
                    <IoIosArrowForward />
                </div>


               <div className='btn'> <a href='#'>Получить доступ  к своей кредитной истории</a></div>
            </div>
        </CreditFaqStyled>
    );
}

export default CreditFaq;
