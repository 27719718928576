import styled from "styled-components";
import Main from "./pages/Main";
import Header from "./components/Header";
import { Routes, Route, Navigate } from "react-router-dom";
import News from "./pages/News";
import Activity from "./pages/Activity";
import History from "./pages/History";
import Lising from "./pages/Lising";
import Admin from "./pages/Admin";
import Documents from "./pages/Documents";
import Publications from "./pages/Publications";

function App() {
  return (
    <div className="App">
      <Header/>
     <Routes>
        <Route path={`/main`} element={<Main/>}/>
        <Route path={`/about-us`} element={<News/>}/>
        <Route path={`/reports`} element={<Activity/>}/>

        <Route path={`/news`} element={<History/>}/>
        <Route path={`/projects`} element={<Lising/>}/>
        <Route path={`/documents`} element={<Documents/>}/>
        <Route path={`/publications`} element={<Publications/>}/>
        <Route path={`/admin`} element={<Admin/>} />
        <Route path="*" element={<Navigate to="/main" replace/>}/>
     </Routes>
     
    </div>
  );
}

export default App;
