import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import Newss from './NewsComp';

import { db } from "../firebase";
import {
    collection,
    getDocs,
} from "firebase/firestore";

const NewsStyled = styled.div `
padding: 70px 0;

>div{
    >.news{
        margin-top: 50px;
        >h3{
            
        }

        >.date{
            color: #999;
            font-size: 12px;
            margin: 10px 0 !important;
        }

        >p{
            font-size: 14px;
        }

        >div{
            text-align: right;
            margin-top: 20px;
            >a{
                text-decoration: none;
            }
        }
    }
}
`

const News = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {


        const fetchNews = async () => {
            const usersCollectionRef = collection(db, "news");
            const data = await getDocs(usersCollectionRef);
            setNews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };


        fetchNews();
    }, []);
    return (
        <NewsStyled>
            <div className='container'>
            <h3>Latest News</h3>
                <div className='line'></div>
                {news.map((newss) => (<Newss id={newss.id} title={newss.news_title} data={newss.news_data} text={newss.news_text}/>))}
               
            </div>
        </NewsStyled>
    );
}

export default News;
