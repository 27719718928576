import React, { useState } from 'react';

import styled from 'styled-components';
import { IoSearch } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import SearchBar from "../components/SearchBar.js"

import { IoMenu } from "react-icons/io5";

import { Drawer } from "antd";


import Logo from "../images/logo.png"

const HeaderStyled = styled.div`
>.container {
    padding: 30px 0;
    >div{
        display: flex;
        align-items: center;
        justify-content: space-between;

        >nav{
            display: flex;
            column-gap: 15px;
            >a{
                
                color: #2C4B5F;
                transition: all 0.5s ease;
                cursor: pointer;

                &:hover {
                    color: #A1C2D8;
                    font-weight: 600;
                }
            }
        }

        >div{
            display: flex;
            align-items: center;
            >span{
                color: #2C4B5F;
                font-size: 14px;
                transition: all 0.5s ease;
                cursor: pointer;
                &:hover {
                    
                    color: #A1C2D8;
                    font-weight: 800;
                }
            }
            
            column-gap: 15px;
            
        }

        >h3{
            font-weight: 900;
            font-size: 32px;
            color: #2C4B5F;
        }
        
    }
}
`

const Header = ({mainLabel, secondaryLabel}) => {

    const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    const navigate = useNavigate()

    return (
        <HeaderStyled>
            <div className='container'>
                <div>
                <IoMenu className='burger' onClick={showDrawer} style={{fontSize:"40px", display:"none"}} />

                    <img style={{width:"70px"}} src={Logo}/>

                    <Drawer title="Menu" onClose={onClose} open={open}>
                    <a  onClose={onClose} onClick={()=>{
                            navigate("/main")
                        }}>Home</a>

                        <br/>
                         <br/>
                        <a  onClose={onClose} onClick={()=>{
                            navigate("/about-us")
                        }}>About US</a>

<br/>
<br/>
                        <a  onClose={onClose} onClick={()=>{
                            navigate("/reports")
                        }}>Reports</a>

<br/>
<br/>
                        <a  onClose={onClose} onClick={()=>{
                            navigate("/news")
                        }}>News</a>

<br/>
<br/>
                        <a  onClose={onClose} onClick={()=>{
                            navigate("/projects")
                        }}>Projects</a>

<br/>
<br/>
                        <a  onClose={onClose} onClick={()=>{
                            navigate("/publications")
                        }}>Publications</a>

<br/>
<br/>
                        <a  onClose={onClose} onClick={()=>{
                            navigate("/documents")
                        }}>Documents</a>
      </Drawer>
                   
                    <nav>
                        <a onClick={()=>{
                            navigate("/main")
                        }}>Home</a>
                        <a onClick={()=>{
                            navigate("/about-us")
                        }}>About US</a>
                        <a onClick={()=>{
                            navigate("/reports")
                        }}>Reports</a>
                        <a onClick={()=>{
                            navigate("/news")
                        }}>News</a>
                        <a onClick={()=>{
                            navigate("/projects")
                        }}>Projects</a>
                        <a onClick={()=>{
                            navigate("/publications")
                        }}>Publications</a>
                        <a onClick={()=>{
                            navigate("/documents")
                        }}>Documents</a>
                    </nav>
                    <div>
                        <span>
                        ENG
                        </span>
                        <span onClick={toggleModal}>
                        <IoSearch />
                        </span>
                        
                        
                    </div>
                </div>
            </div>
        <SearchBar isOpen={isModalOpen} onClose={toggleModal} />
        </HeaderStyled>
    );
}

export default Header;
