import styled from 'styled-components';

const NewsStyled = styled.div `
 >.news{
        margin-top: 50px;
        >h3{
            
        }

        >.date{
            color: #999;
            font-size: 12px;
            margin: 10px 0 !important;
        }

        >p{
            font-size: 14px;
        }

        >div{
            text-align: right;
            margin-top: 20px;
            >a{
                text-decoration: none;
            }
        }
    }

`

const News = ({id,title, data, text}) => {
    return (
        <NewsStyled key={id}>
        <div className='news'>
                <h3>{title} </h3>
                <p className='date'>{data}</p>
                <p>{text}</p>
                {/* <div>
                    <a href='#'>Подбробнее</a>
                </div> */}
            </div>

        </NewsStyled>
    )
}

export default News;