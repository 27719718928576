import React from 'react';
import styled from 'styled-components';
import { IoSearch } from "react-icons/io5";

const SearchBarStyled = styled.div`


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal-content {
    background: white;
    padding: 20px;
    position: relative;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    >input {
        border: none;
        outline: none;
       width: 100%;
}
}
}



.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}



`

const SearchBar = ({ isOpen, onClose, children }) => {

    if (!isOpen) return null;


    return (
        <SearchBarStyled>
            <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <input placeholder='Введите запрос поиска'/><IoSearch/>
            </div>
        </div>
        </SearchBarStyled>
    );
}

export default SearchBar;
