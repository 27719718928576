// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDDlRjGZ2HVc1n0FlwOgjQOUIbv0T3FSCE",
    authDomain: "prizma-8e029.firebaseapp.com",
    projectId: "prizma-8e029",
    storageBucket: "prizma-8e029.appspot.com",
    messagingSenderId: "631727691935",
    appId: "1:631727691935:web:69b8b644942903a1442aaa"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
export { db };
