import React, { useState, useEffect } from "react";
import Banner from '../components/Banner';
import File from '../components/File';
import Footer from '../components/Footer';
import { db } from "../firebase";
import {
    collection,
    getDocs,
} from "firebase/firestore";
import styled from "styled-components";

const DocumentsStyled = styled.div `
>.container{
    padding: 70px 0;

    >.docs{
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
    }

}

`


const Documents = () => {
    const [documents, setDocuments] = useState([]);


    useEffect(() => {


        const fetchReports = async () => {
            const usersCollectionRef = collection(db, "documents");
            const data = await getDocs(usersCollectionRef);
            setDocuments(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };


        fetchReports();
    }, []);

    return (
        <DocumentsStyled>
             <Banner mainLabel="Documents of Organization"/>
            <div className='container'>
                <h3>Documents</h3>
                <div className='line'></div>

                <div className="docs">
                    {documents.map((document) => (

                        <File id={document.id} name={document.doc_name} link={document.doc_file} />


                    ))}
                </div>

            </div>

            <Footer />
        </DocumentsStyled>
    );
}

export default Documents;
