import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import Banner from '../components/Banner';
import Chairs from '../images/chairs.png'
import Law from '../images/law.png'
import Footer from '../components/Footer';
import HR from '../images/hr.png'
import Tech from '../images/tech.png'
import World from '../images/world.png'
import Reports from '../images/reports.png'
import Safety from '../images/safety.png'

import { db } from "../firebase";
import {
    collection,
    getDocs,
} from "firebase/firestore";

import File from "../components/File";





const ActivityStyled = styled.div`
>.container{
    padding: 70px 0;

    >.docs{
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
    }

    >.block-1{
        display: flex;
        justify-content: space-between;
        color: #fff;
        >.chairs{
            background-image: url(${Chairs});
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 500px;
            height: 230px;
            padding: 180px 0 0 20px; 
        }

        >.law{
            background-image: url(${Law});
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 500px;
            height: 230px;
            padding: 180px 0 0 20px; 
        }
    }

    >.block-2{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        color: #fff;

        >.block{
            >.tech{
                background-image: url(${Tech});
                background-repeat: no-repeat;
                background-size: 100% 100%;
                width: 680px;
                height: 230px;
                padding: 180px 0 0 20px; 
            }

            >.safety{
                margin-top: 20px;
                background-image: url(${Safety});
                background-repeat: no-repeat;
                background-size: 100% 100%;
                width: 680px;
                height: 230px;
                padding: 180px 0 0 20px; 
            }
        }

        >.hr{
            background-image: url(${HR});
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 320px;
            height: 480px;
            padding: 420px 0 0 20px; 
        }
    }

    >.block-3{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        color: #fff;
        >.world{
            background-image: url(${World});
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 500px;
            height: 230px;
            padding: 180px 0 0 20px; 
        } 
        >.reports{
            background-image: url(${Reports});
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 500px;
            height: 230px;
            padding: 180px 0 0 20px; 
        }   
    }
}
`



const Activity = () => {

    const [reports, setReports] = useState([]);

    useEffect(() => {


        const fetchReports = async () => {
            const usersCollectionRef = collection(db, "reports");
            const data = await getDocs(usersCollectionRef);
            setReports(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };


        fetchReports();
    }, []);


    return (
        <ActivityStyled>
            <Banner mainLabel="Reports" secondLabel=" Reports of Organization" />
            <div className='container'>
                <h3>Report Files</h3>
                <div className='line'></div>

                <div className="docs">
                    {reports.map((report) => (

                        <File id={report.id} name={report.report_name} link={report.report_file} />


                    ))}
                </div>

                {/* 
                <div className='block-1'>
                    <div className='chairs'>
                        <h2>Аппарат правления</h2>
                    </div>
                    <div className='law'>
                        <h2>Правовое обеспечение</h2>
                    </div>
                </div>

                <div className='block-2'>
                    <div className='hr'>
                        <h2>Подготовка кадров</h2>
                    </div>
                    <div className='block'>
                        <div className='tech'>
                            <h2>Технологии</h2>
                        </div>
                        <div className='safety'>
                            <h2>Банковская безопасность</h2>
                        </div>
                    </div>
                </div>



                <div className='block-3'>
                    <div className='world'>
                        <h2>Международная деятельность</h2>
                    </div>

                    <div className='reports'>
                        <h2>Отчеты</h2>
                    </div>
                </div> */}
            </div>

            <Footer />

        </ActivityStyled>
    );
}

export default Activity;
