import React from 'react';
import styled from 'styled-components';
import Background from "../images/header-background.png"


const BannerStyled = styled.div `
 background-image: url(${Background});
    padding:80px 0 ;
    >div{
        color:#fff
    }
`

const Banner = ({mainLabel, secondLabel}) => {
    return (
        <BannerStyled>
           
                <div className='container'>
                <h1>{mainLabel}</h1>
                <br/>
                <h3>{secondLabel}</h3>
                </div>
            
        </BannerStyled>
    );
}

export default Banner;
